<template>
  <div>
    <div class="payBox">
      <p class="payBox_p">提交申请</p>

      <div class="payBox-main">
        <el-descriptions title="申请信息" column="4">
          <el-descriptions-item label="证书名称" v-if="CN">{{ CN }}</el-descriptions-item>
          <el-descriptions-item label="延期年限"  v-if="year">{{
            `${year}`
          }}</el-descriptions-item>
          <el-descriptions-item label="支付费用" v-if="originalPrice"
            >￥{{ originalPrice }}</el-descriptions-item
          >
          <el-descriptions-item label="业务类型" v-if="certOperationType">{{
            certOperationType
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="payBox-footer">
        <h1>请选择支付方式</h1>
        <el-divider class="clear"></el-divider>
        <div class="paly clear">
          <div style="margin-left: 25px">
            <el-radio v-model="radio" label="true" class="radio">
              <p class="left">
                <img :src="imgStr" />
              </p>
              <span
                style="
                  display: inline-block;
                  margin-top: 10px;
                  padding-left: 8px;
                "
                >支付宝支付</span
              >
            </el-radio>
          </div>
          <el-divider></el-divider>
          <p
            style="
              font-size: 14px;
              line-height: 3;
              text-align: start;
              margin-left: 25px;
              margin-top: 5px;
              color: #989b9e;
            "
          >
            在线支付金额:<span
              style="color: #2086fa; font-weight: 550; font-size: 16px"
              >￥{{ totalPrice }}</span
            >
            <span v-if="totalPrice != originalPrice" style="color: #2086fa; font-weight: 500; font-size: 13px">
              (原价：￥{{ originalPrice }})</span
            >
          </p>
        </div>
      </div>
      <div class="btn">
        <el-button style="background-color: #f4f6f8" @click="routerBack"
          >返回上一步</el-button
        >
        <el-button type="primary" @click="submitPlay">确认支付</el-button>
      </div>
    </div>
    <!-- 验证 PIN弹窗 -->
    <el-dialog
      title="PIN码验证"
      :visible.sync="dialogFormVisible"
      width="432px"
    >
      <el-form :model="form" ref="form" >
        <el-form-item
          label="请输入PIN码"
          style="margin: 10px 0 25px 3px"
          class="form-item"
          prop="PIN"
          :rules="[{ required: true, message: 'PIN不能为空', trigger: 'blur' }]"
        >
          <el-input
            class="el_input"
            v-model="form.PIN"
            placeholder="请输入PIN码"
            show-password
            
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer clear">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')" :loading="pinLoading">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 支付二维码 -->
    <Modal
      :modalVisible="modalVisible"
      :qrcodeBase64="qrcodeBase64"
      :paySuccess="paySuccess"
      @modalOpen="modalOpen"
      :orderId="orderId"
    />
  </div>
</template>

<script>
import zfbLog from "../../assets/zfb.png";
import Modal from "./components/modal";
import PKIDriver from "../../utils/pki-driver";
import { CHECK_YEAR } from "./data";
import {
  getOrderInfo,
  getPrice,
  postPriceValue,
  precreate,
  queryOrderInfo,
} from "./serves";
import { getGoodsList } from "../../utils/public-instrument";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      radio: false,
      CN: "",
      year: "",
      price: "￥180.00",
      certOperationType: "延期申请",
      imgStr: zfbLog,
      dialogFormVisible: false,
      modalVisible: false,
      form: {
        PIN: "",
      },
      playCode: "",
      qrcodeBase64: "",
      paySuccess: "",
      originalPrice: "0",
      totalPrice: "0",
      fingerprint: "",
      orderId: "",
      pinLoading:false,
    };
  },

  watch: {
    dialogFormVisible: {
      handler(val) {
       if(!val){
        this.form.PIN =''
       }
      },
      immediate: true,
    },
  },


  methods: {
    routerBack() {
      this.$router.back();
    },
    async submitForm(formName) {
      this.pinLoading = true
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
      });
      const sUserPin = this.form.PIN;
      if (sUserPin) {
        const pkiDriver = this.getPkiDriver();
        const DeviceSN = localStorage.getItem("DeviceSN");
        pkiDriver
          .verifyUserPin(DeviceSN, sUserPin)
          .then((res) => {
            if (res == 0) {
              this.dialogFormVisible = false;
              this.pinLoading = false;
              if (!this.$route.query.orderId) {
                const info = getGoodsList();
                const params = {
                  certId: info.certId,
                  certOperationType: "OP_POSTPONE",
                  device: "UKEY",
                  payWay: "ALIPAY_APP",
                  projectId: info.projectId,
                  voucher: this.$route.query.voucher || undefined,
                  fingerprint: this.fingerprint,
                  extend: {
                    DURATION: this.$route.query.checkSetMeal,
                    SEAL: this.$route.query.isSeal == true ? true : false,
                  },
                };
                postPriceValue(params).then((res) => {
                  const {freeFlag,code} = res.data
                  if(freeFlag){
                    this.getOrderStatus();
                  }else{
                  this.qrcodeBase64 = code;
                  this.modalOpen(true);
                  this.getOrderStatus();
                  }
               
                });
              } else {
                precreate({ orderId: this.$route.query.orderId }).then(
                  (res) => {
                    const { data } = res;
                    this.qrcodeBase64 = data;
                    this.modalOpen(true);
                    this.queryOrderStatus();
                  }
                );
              }
            }
          })
          .catch((error) => {
            if (error.errorMsg) {
              this.$message({
                message: error.errorMsg,
                type: "error",
              });
            }
            this.dialogFormVisible = false;
            this.pinLoading = false
          });
      }
    },
    getOrderStatus() {
      const info = getGoodsList();
      queryOrderInfo({ serialNumber: info.certSerial })
        .then((res) => {
          const { data } = res;
          if (data.orderStatus == "TO_BE_POSTPONED") {
            this.orderId = data.orderId;
            this.modalOpen(false);
            this.paySuccess = true;
          } else {
            this.timer = setTimeout(() => {
              this.getOrderStatus();
            }, 3000);
          }
        })
        .catch(() => {
          this.modalOpen(false);
          this.paySuccess = false;
        });
    },
    queryOrderStatus() {
      getOrderInfo({ orderId: this.$route.query.orderId })
        .then((res) => {
          const { data } = res;
          if (data.orderStatus == "TO_BE_POSTPONED") {
            this.modalOpen(false);
            this.paySuccess = true;
          } else {
            this.timer = setTimeout(() => {
              this.queryOrderStatus();
            }, 3000);
          }
        })
        .catch(() => {
          this.modalOpen(false);
          this.paySuccess = false;
        });
    },
    modalOpen(val) {
      this.modalVisible = val;
    },
    submitPlay() {
      if (this.radio) {
        this.dialogFormVisible = true;
      } else {
        this.$message({
          message: "请选择支付方式",
          type: "warning",
        });
      }
    },
    async fetchPrice(params) {
      try {
        const res = await getPrice(params);
        const { originalPrice, totalPrice } = res.data;
        this.originalPrice = originalPrice;
        this.totalPrice = totalPrice;
      } catch (error) {}
    },
    async fetchOrder(params) {
      const res = await getOrderInfo(params);
      const {
        certName,
        specifications,
        finalPrice,
        originalPrice,
        fingerprint,
      } = res.data;
      this.CN = certName;
      this.year = CHECK_YEAR[specifications.DURATION];
      this.totalPrice = finalPrice;
      this.originalPrice = originalPrice;
      this.fingerprint = fingerprint;
    },
    getPkiDriver() {
      if (this.pkiDriver) {
        return this.pkiDriver;
      }
      this.pkiDriver = new PKIDriver();
      return this.pkiDriver;
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  mounted() {
    const {
      certificate,
      checkSetMeal,
      orderId,
      fingerprint,
      totalPrice,
      originalPrice,
    } = this.$route.query;
    if (!orderId) {
      this.CN = certificate;
      this.year = CHECK_YEAR[checkSetMeal];
      
      this.fingerprint = fingerprint;
      this.totalPrice = totalPrice;
      this.originalPrice = originalPrice;
    }
    if (orderId) {
      this.orderId = orderId;
      this.fetchOrder({ orderId: orderId });
    }
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
}

.clear {
  clear: both;
}
.left {
  float: left;
}
:deep(.el-descriptions-item__cell) {
  line-height: 2.5 !important;
}
:deep(.el-divider) {
  background: #f4f6f8;
  height: 1px;
}
:deep(.el-message--warining) {
  background: #ffebcb;
  border-color: #ffebcb;
}
:deep(.el-button--primary) {
  background: #2086fa;
  border: #2086fa;
}
.payBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f6f8;
  box-sizing: border-box;
  min-height: calc(100vh - 260px);
  padding-top: 2%;
}
.payBox_p {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #313438;
  margin-bottom: 20px;
}
.payBox-main {
  margin: 0 auto;
  width: 60%;
  background: #ffffff;
  height: 130px;
  padding: 25px;
  min-width: 800px;
}
.payBox-footer {
  margin: 0 auto;
  width: 60%;
  background: #ffffff;
  height: 180px;
  margin-top: 10px;
  min-width: 800px;
}
h1 {
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #313438;
  float: left;
  margin: 24px 0 30px 25px;
}

.el_input {
  width: 383px;
  height: 32px;
  border-radius: 2px;
}

:deep(.el-input__inner) {
  height: 35px;
}

:deep(.el-dialog__title) {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  float: left;
}

:deep(.el-dialog__body) {
  padding: 15px 15px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

.form-item {
  margin: 10px 0px 25px 3px;
  width: 300px;
  background: #ffffff;
  border-radius: 3px;
}
.radio {
  display: flex;
  align-items: center;
  margin: 11px 0 11px 0;
}
.footer {
  margin-top: 10px;
}
.btn {
  margin: 52px 0;
}
.btn button {
  margin-left: 16px;
  width: 142px;
  height: 32px;
  background: #2086fa;
  border-radius: 2px;
  padding: 8px 22px;
}
</style>