import api from '../../utils/api'

export function getPrice(params) {
    return api('/ca/onlc/price/search', {
        method: 'POST',
        params: {...params}
    })
}

export function postPriceValue(params) {
    return api('/ca/onlc/oss/manage/submit', {
        method: 'POST',
        params: params
    })
}

export function getOrderInfo(params) {
  return api('/ca/onlc/order/get-by-orderId', {
      method: 'GET',
      params: params
  })
}

export function queryOrderInfo(params) {
  return api('/ca/onlc/order/get-by-serialNumber', {
      method: 'GET',
      params: params
  })
}

export function precreate(params) {
  return api('/ka/order/order-wap/pay/precreate', {
      method: 'GET',
      params: params
  })
}