<template>
  <!-- <div class="payBox"> -->
    <el-dialog title="去支付" width="550px" :visible.sync="visibleModal">
      <div class="payCode">
        <img :src="codeBase64" alt="" width="200px" />
        <div style="margin-top: -10px; color: #2086fa;font-weight: 400;">请使用支付宝【扫一扫】完成支付</div>
      </div>
    </el-dialog>
  <!-- </div> -->
</template>

<script>
export default {
  props: ["modalVisible","qrcodeBase64","paySuccess","orderId"],
  data() {
    return {
      visibleModal: this.modalVisible,
      payFlag:this.paySuccess,
      isFlag: true,
      time: null,
      codeBase64:this.qrcodeBase64,
    };
  },
  methods: {
    result(val) {
      if (val) {
        this.$confirm(
          
          "您已支付成功！您可以点击下方按钮进行延期操作",
          "支付成功",
        
          {
            confirmButtonText: "立即延期",
            cancelButtonText: "取消",
            type: "success",
          }
        ).then(() => {
          this.visibleModal = false;
          this.$router.push({
          name: "operation",
          query: {
            orderId:this.orderId
          },
        });
        });
      } else  {
        this.visibleModal = false;
        this.$confirm(
          "支付失败！您可以点击下方按钮继续支付操作",
          "支付失败提示",
          {
            confirmButtonText: "继续支付",
            cancelButtonText: "取消",
            type: "error",
          }
        );
      }
    },
  },
  mounted() {
    
  },
  updated() {   
    if (!this.visibleModal) {
      this.$emit("modalOpen", false);
    }
  },

  watch: {
    modalVisible: {
      handler(val) {
        this.visibleModal = val;
      },
      immediate: true,
    },
    qrcodeBase64:{
      handler(val) {
        this.codeBase64 = val;
      },
      immediate: true,
    },
    paySuccess:{
      handler(val) {
        this.result(val)
      },
    }
  },
};
</script>
<style scoped>

.payCode {
  text-align: center;
  height: 250px;
}
:deep(.el-button--primary) {
  background: #2086fa;
  border: #2086fa;
}
</style>