
// token
export function getToken() {
    return localStorage.getItem('oss-token');
}

export function setToken(token) {
    return localStorage.setItem('oss-token', token);
}


// 数据是否为空
export function isEmpty(params) {
    return typeof params === 'undefined' || params === "" || params === null || JSON.stringify(params) === "{}" || params.length === 0;
}

/** 设置订单信息 */
export function setGoodsList (val) {
  sessionStorage.setItem('goods-list', JSON.stringify(val))
}
/** 获取订单信息 */
export function getGoodsList () {
  const val = sessionStorage.getItem('goods-list')
  if (val) {
    return JSON.parse(val)
  }
  return ''
}